const resources = require('./config/resources.json')

const cloudfront = resources.cloudfront.url

const imageCDNUrl = path => {
  return `https://${cloudfront}/${path}`
}

export {
  imageCDNUrl
}
