import React from 'react'
import { Footer, Navbar } from 'web-components'
import SEO from '../components/seo'
import { imageCDNUrl } from '../helpers'
import '../css/styles.scss'

import contact from '../config/text/contact.json'
import pages from '../config/navPages'
import { social } from '../config/resources'

const Layout = (props) => {
  return (
    <div className='layout'>
      <SEO
        title={props.title}
        image={imageCDNUrl(props.heroSrc || 'heros/coffee-beans-box.jpg')}
        {...{ description: props.description }}
      />
      <Navbar className='navbar-light' logo={imageCDNUrl('grey-logo.png')} logoAria='The Kent Coffee Company' pages={pages}>
        <div className='navContact d-none d-xl-block'>
          MAIN OFFICE: {contact.phone.office}<br/>
          ENQUIRIES: {contact.phone.mark}<br/>
          TECHNICAL: {contact.phone.lee}
        </div>
        <div className='navContact d-none d-lg-block d-xl-none'>
          <svg className='svgImg' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M6.176 1.322l2.844-1.322 4.041 7.89-2.724 1.341c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-7.862 3.591-19.103-18.258-11.385-22.281zm1.929 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.497-2.251-4.398z'/></svg>
          : {contact.phone.office}
        </div>
      </Navbar>
      {props.heroSrc &&
        <div className='heroContainer d-lg-block'>
          <div className='position-relative vignette'>
            <img src={imageCDNUrl(props.heroSrc)} className='heroImg' alt='' />
          </div>
          {props.heroText &&
            <h1 className="heroText mr-4">{props.heroText}</h1>
          }
        </div>
      }
      <div className='p-4'>{props.children}</div>
      <Footer
        contact={{
          address: contact.address,
          phone: contact.phone.office
        }}
        name='The Kent Coffee Company Limited'
        pages={pages}
        social={social}
      >
        <a className='brand d-inline' href='/'><img className='brand-logo' src={imageCDNUrl('grey-logo.png')} alt='Kent Coffee' width='140' height='37'/></a>
        <p className='pt-2'>Serving the East Kent area, with our own engineers and delivery drivers, as well as the close relationships we develop with all our customers, we are offering a total coffee service, from machine, installation and training, to a range of superb products unique to us.</p>
      </Footer>
    </div>
  )
}

export default Layout
